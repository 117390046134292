<template>
<div id="FirstSlide">

  <div id="FirstSlide__content">

    <div id="FirstSlide__name">
      <div class="picture-of-me">
        <div class="inner-picture"></div>
      </div>
      <div class="my-name">
        <div class="first-name">Filipe</div>
        <div class="last-name">Sousa</div>
      </div>
    </div>

    <div id="FirstSlide__description">
      <!--      <img src="" alt="" id="image_preload">-->
      <div class="center_description">
        <div class="description__title">Portfolio</div>
        <p>In order to highlight some of the things I have done, I created this online portfolio.</p>
        <p>You will be able to navigate thought the site, by using the arrows in the corners of the screen, some slides
          might look simple, but took effort to build.</p>
        <p>In the top left you will find the MAP where you can see a mini map of all the slides, you will also find an
          easy mode button in case you want to make things easier... but dont give up early.</p>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { onUnmounted } from 'vue';

export default {
  name: 'Start',
  setup () {

    onUnmounted(() => {
      let img_1 = new Image;
      img_1.src = '/reflection/back.png';

      let img_2 = new Image;
      img_2.src = '/reflection/bottom.png';

      let img_3 = new Image;
      img_3.src = '/reflection/left.png';

      let img_4 = new Image;
      img_4.src = '/reflection/right.png';

      let img_5 = new Image;
      img_5.src = '/reflection/sky.png';

      let img_6 = new Image;
      img_6.src = '/reflection/sunset.png';
    });

  },
};
</script>

<style lang="stylus" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap');
#FirstSlide
  position absolute
  overflow-x hidden
  overflow-y auto
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  box-shadow 0 10px 30px 0 rgba(0, 0, 0, 0.32)
  background-color #faf5ee
  background-image url("../../public/unsplash/pic_15.jpg");
  background-position bottom -450px right -450px
  #FirstSlide__content
    background-image radial-gradient(circle at 20% 0%, rgba(255, 127, 64, 0.1), transparent);
    height 100%
    display flex
    align-items stretch
    justify-content stretch
    #FirstSlide__name
      background-image url("../../public/first_page/f.svg")
      background-position bottom left
      background-repeat no-repeat
      background-size 50%
      min-width 320px
      padding 0 30px
      text-align left
      display flex
      align-items center
      flex-direction column
      .picture-of-me
        margin 20px 0
        position relative
        width 170px
        height 170px
        border-radius 400px
        border 1px dashed black
        .inner-picture
          position absolute
          top 10px
          left 10px
          right 10px
          bottom 10px
          border-radius 400px
          background-image url("../../public/first_page/cv_picture.jpg")
          background-position center
          background-repeat no-repeat
          background-size cover
      .my-name
        text-align center
        text-transform uppercase
        -moz-osx-font-smoothing grayscale
        text-shadow none
        .first-name
          color black
          font 300 45px / 70px Montserrat
        .last-name
          -webkit-text-stroke 1px transparent
          color #000
          font 600 45px / 70px Montserrat
        &:hover
          .last-name
            color transparent
            -webkit-text-stroke 1px #000
    #FirstSlide__description
      align-self stretch
      text-align left
      .center_description
        font 400 18px / 32px Montserrat
        padding 40px 400px 0 0
        .description__title
          position relative
          font 400 25px Montserrat
          letter-spacing 2px
          text-transform uppercase
          margin-bottom 40px
          &::after
            content ''
            position absolute
            bottom -10px
            left 0
            width 100px
            border-bottom 1.5px solid #2c3e50
        p
          margin-bottom 20px

#image_preload
  width 100px
  height 100px
</style>
